import type { BookingSlot } from '~/@types/graphql-police'
import { createGiftPaymentTransaction } from '~/api/gift-payment'
import { createPaymentTransaction } from '~/api/payment'
import { getBookingSlotById, getOpenBookingSlots } from '~/api/slots'
import { queryOptions } from '@tanstack/react-query'

export function getOpenBookingSlotsOptions() {
  return queryOptions({
    queryKey: ['booking-slots-list'],
    queryFn: () => {
      return getOpenBookingSlots()
    }
  })
}

export function getBookingSlotByIdOptions(slotId: BookingSlot['id']) {
  return queryOptions({
    queryKey: ['booking-slot', slotId],
    queryFn: () => {
      return getBookingSlotById(slotId)
    }
  })
}

export function getCreatePaymentTransactionOptions(
  accessToken: string,
  slotId?: BookingSlot['id']
) {
  return queryOptions({
    queryKey: ['create-payment-transaction', slotId],
    queryFn: () => {
      return createPaymentTransaction(accessToken, slotId)
    }
  })
}

export function getCreateGiftPaymentTransactionOptions() {
  return queryOptions({
    queryKey: ['create-gift-payment-transaction'],
    queryFn: () => {
      return createGiftPaymentTransaction()
    }
  })
}
